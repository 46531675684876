<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            indeterminate
            size="50"
            color="primary"
        ></v-progress-circular>
      </v-overlay>

      <div class="main">

        <v-row>

          <v-col cols="12">
            <div class="d-flex justify-space-between">

              <v-btn text
                     color="grey"
                     class="rounded-lg"
                     @click="$router.push('/recoveries')">
                <v-icon left>mdi-arrow-left</v-icon>
                Retour
              </v-btn>

              <v-spacer/>

              <v-btn class="rounded-lg"
                     @click="handleExport"
                     depressed
                     color="gifty"
                     dark>
                <v-icon left>mdi-download</v-icon>
                Exporter
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">

            <v-card class="rounded-lg shadow">

              <v-card-title>

                <div>
                  <v-text-field
                      v-model="keyword"
                      rounded
                      filled
                      dense
                      append-icon="mdi-magnify"
                      clearable
                      single-line
                      hide-details
                      placeholder="Recherche..."
                  ></v-text-field>
                </div>

                <v-spacer/>

                <v-btn text color="grey" class="rounded-lg">
                  <v-icon left>mdi-reload</v-icon>
                  Actualiser
                </v-btn>

                <v-btn text color="grey">
                  <v-icon left>mdi-filter-outline</v-icon>
                  Filtre
                </v-btn>

              </v-card-title>

              <v-divider/>

              <v-card-text v-if="isLoading">
                <v-skeleton-loader type="table"/>
              </v-card-text>

              <div v-else>

                <v-card-text>

                  <v-simple-table v-if="recuperators.length">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Active</th>
                        <th>Nom complet</th>
                        <th>Nom d'utilisateur</th>
                        <th>Email</th>
                        <th>Téléphone</th>
                        <th>POS</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item,i) in payments" :key="i">
                        <td>
                          <v-switch class="mt-0 pt-0"
                                    readonly
                                    v-model="active"
                                    hide-details color="gifty"/>
                        </td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.username }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.phone }}</td>
                        <td>
                          <v-chip color="primary" dark small>
                            <v-icon left small>mdi-home-group</v-icon>
                            {{ item.salepoints.length }}
                          </v-chip>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div v-else>
                    <NoResults/>
                  </div>

                </v-card-text>

              </div>

            </v-card>
          </v-col>

        </v-row>
      </div>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";

export default {
  components: {NoResults},
  data() {
    return {
      isLoading: false,
      overlay: false,
      keyword: true,
      payments: [],

    };
  },
  methods: {
    getPayments() {
      this.isLoading = true
      HTTP.get('/v1/recoveries/recuperators').then(res => {
        this.isLoading = false
        this.recuperators = res.data.data
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  created() {
    this.getPayments();
  },
};
</script>

<style scoped></style>
